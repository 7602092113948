<template>
  <div class="app-container">
    <div class="login-wrapper">
      <div class="display">
        <img src="@/assets/images/logo.png" alt="" />
      </div>

      <div class="content">
        <form>
          <div class="form-wrapper">
            <div class="input-wrapper">
              <Input
                id="email"
                type="text"
                label="Email"
                style="margin-bottom: 24px"
                v-model="email"
                :error="$v.email.$error"
                @errorStatus="emailError = false"
              ></Input>
              <Input
                id="password"
                :error="$v.password.$error"
                @errorStatus="passwordError = false"
                type="password"
                v-model="password"
                label="passwords"
              ></Input>
            </div>

            <Links style="margin: 20px 0" to="/recuperar-senha" label="Esqueci minha senha"></Links>

            <ButtonFilled
              :isLoading="isLoading"
              :onClick="login" 
              label="Entrar">
            </ButtonFilled>

            <Links style="margin: 20px 0" to="/cadastro" label="Quero criar uma conta"></Links>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

import Input from "../components/common/Input/Input.vue";
import Links from "../components/common/Links/Links.vue";
import ButtonFilled from "../components/common/Button/ButtonFilled.vue";

import { mapActions } from 'vuex'
//import api from '../plugins/axios'

export default {
  components: {
    Input,
    Links,
    ButtonFilled,
  },
  data() {
    return {
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      isLoading: false
      
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    ...mapActions([
      'LogIn',
    ]),
    
    login() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      this.$store.dispatch('LogIn', {email:this.email, senha: this.password}).then(()=>{
        this.isLoading = false;
      }).catch((err) => {
        console.log(err)
        if(err.response.status===401)
          this.$toast.error("Email e/ou senha incorretos", {
            timeout: 2000,
          });
        else
          this.$toast.error("Ocorreu um erro inesperado.", {
            timeout: 2000,
          });
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 24px;
}

.login-wrapper img {
  width: 113px;
  height: 118px;
}

form {
  width: 100%;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 40vh;
  width: 100%;
}

.input-wrapper {
  width: 100%;
}

@media screen and (min-width: 700px) {

  
  .login-wrapper {
    width: 500px;
    height: 700px;
    border-radius: 15px;
    background: #fff;
  }
}
</style>